import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Dataframe_Cover.jpg';
import metaImage from './assets/meta.jpg';

const DataframesPage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="data-type-dataframes"
      helmet={{
        title: 'TileDB - Supercharge your dataframes analysis with cloud-native arrays',
        description:
          'TileDB arrays supercharge dataframes analysis, with faster performance vs. CSVs & Parquet on queries that span multiple columns.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      heroImage={BackgroundImage}
      icon={dataTypes.dataframes.icon}
      header="Dataframes"
      title="Supercharge your dataframes analysis with cloud-native arrays"
      overview={
        <>
          <p>
            Tabular data is one of the most prevalent data types across all industries, but formatting it as CSV and Parquet files, and
            resorting to storing them in data lakes and cloud object storage as flat files, leads to extra data wrangling and management
            hassles.
          </p>
          <p>
            Add flexibility to your dataframe analysis with open-source, cloud-native TileDB arrays. With the multi-dimensionality of
            TileDB, you can effectively index on more than one column in order to boost performance on multi-attribute queries. TileDB
            supports popular languages like Python and R, with convenient accessor functions that integrate with existing dataframe tools.
          </p>
          <p>
            TileDB is designed for extreme performance on cheap cloud object storage, eliminating the need for in-memory engines like Apache
            Spark and external query cluster services like Presto and Trino.
          </p>
          <p>
            Take advantage of secure data governance and serverless computation with TileDB Cloud. Extensive integrations allow you to use
            hosted Jupyter environments and TileDB Cloud UDFs to process large-scale workflows, while continuing to use popular tools like
            Pandas and Apache Arrow. Enjoy extreme, scalable performance while minimizing your TCO.
          </p>
        </>
      }
      relatedItems={[industries.maritime, industries.telecom, industries.finance]}
    />
  );
};

export default DataframesPage;
